

@media screen and (min-width: 1024px) {
    #mission, #partner-box{
    background-color: rgba(249, 238, 210, 0.3)
  }
}
@media screen and (max-width: 370px) {
  .typograph, .revolution{
    font-size: 14px;
  }
  .revolution-desc, .emp-desc{
    font-size: 13px;
  }
}